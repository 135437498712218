import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import EditBufferComponent from './components/EditBufferComponent';
import Status403Component from './components/Status403Component';
import './App.css';

function App() {
  const [editorValue, setEditor] = useState('');
  const [editors, setEditors] = useState([]);

  return (
    <Router>
      <Container fluid className="App">
        <header className="App-header">
          <ButtonGroup class="mb-2" id="editorSwitcher">
            {editors.map((editor, idx) => (
              <ToggleButton
                key={idx}
                id={`editor-${idx}`}
                type="radio"
                variant="secondary"
                name="editor"
                value={editor.value}
                checked={editorValue === editor.value}
                onChange={e => setEditor(e.currentTarget.value)}
              >
                {editor.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
          <Row>
            <Routes>
              <Route path='/:game/:id' element={<EditBufferComponent editor={editorValue} onLoaded={(editors, activeEditor) => { 
                setEditors(editors);
                setEditor(activeEditor);
               }} />} />
              <Route path='*' element={<Status403Component/>} />
            </Routes>
          </Row>
        </header>
      </Container>
    </Router>
  );
}

export default App;
