import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const xtermColors = require('../common/xtermColors.json');

class SimpleEditorComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      parsed: "",
      modalVisible: false,
    };
  }

  componentDidMount() {
    this.handleChange(this.props.value);
  }

  handleChange = raw => {
    if (typeof this.props.onChange == 'function') {
      this.props.onChange(raw);
    }

    const parsed = raw.replace(/&[xgbczGBCrOpwRYPWUID]|&\d{3}/gm, (match, offset, string) => {
      var color = null;
      if (match.length === 4) {
        color = xtermColors[parseInt(match.replace("&", ''))];
      } else {
        switch (match) {
          case '&x':
            color = xtermColors[0];
            break;
          case '&g':
            color = xtermColors[2];
            break;
          case '&G':
            color = xtermColors[10];
            break;
          case '&b':
            color = xtermColors[4];
            break;
          case '&c':
            color = xtermColors[6];
            break;
          case '&B':
            color = xtermColors[12];
            break;
          case '&C':
            color = xtermColors[14];
            break;
          case '&r':
            color = xtermColors[1];
            break;
          case '&R':
            color = xtermColors[9];
            break;
          case '&p':
            color = xtermColors[5];
            break;
          case '&P':
            color = xtermColors[13];
            break;
          case '&O':
            color = xtermColors[3];
            break;
          case '&Y':
            color = xtermColors[11];
            break;
          case '&z':
            color = xtermColors[8];
            break;
          case '&w':
            color = xtermColors[7];
            break;
          case '&W':
            color = xtermColors[15];
            break;
          default:
            color = "#c0c0c0";
        }
      }
      if (color) {
        return `</font><font color="${color}">`
      } else {
        return "";  /* Strip non-color tags */
      }
    })

    this.setState({ parsed: `<font>${parsed}</font>` });
  }

  render() {
    return (
      <div id="SimpleEditorContainer">
        <div class="container-fluid" id="intro">
          <Row>
            <Col>
              <p>
                Compiled by @Xerakon. Credit to @Xavier for original concept. Thanks to @Rengawm for 256color conversion assistance.<br/>
                To simulate what your work will look like on LotJ, you can use LotJ color codes in the blue box below and a preview will appear to the right to reflect changes.<br/>
                <a href="https://xerakon.io/lotj/view256color.html" target="_blank" rel="noreferrer" onClick={this.handleModalOpen}>Click here</a> for the full list of accepted color tokens. The older and more commonly-used tokens are:
              </p>
            </Col>
          </Row>
          <Row>
            <div class="col-3">
              <br/>
              &g - <font color="#008000">Green</font><br/>
              &b - <font color="#000080">Dark Blue</font><br/>
              &c - <font color="#008080">Cyan</font><br/>
              &z - <font color="#808080">Dark Grey</font><br/>
              &G - <font color="#00FF00">Light Green</font><br/>
              &B - <font color="#0000FF">Blue</font><br/>
              &C - <font color="#00FFFF">Light Blue</font>
            </div>
            <div class="col-3">
              &r - <font color="#800000">Red (blood)</font><br/>
              &O - <font color="#808000">Orange (brown)</font><br/>
              &p - <font color="#800080">Purple</font><br/>
              &w - <font color="#C0C0C0">Gray (default color)</font><br/>
              &R - <font color="#FF0000">Light Red</font><br/>
              &Y - <font color="#FFFF00">Yellow</font><br/>
              &P - <font color="#FF00FF">Pink</font><br/>
              &W - <font color="#FFFFFF">White</font>
            </div>
          </Row>
        </div>
        <div class="container-fluid" id="main">
          <Row>
            <div class="col">
              <form>
                <textarea class="taInput" id="taInput" cols="80" wrap="hard" onChange={ e => this.handleChange(e.target.value) }>
                  { this.props.value }
                </textarea>
              </form>
            </div>
            <div class="col">
              <div class="taDisplay" id="taDisplay" dangerouslySetInnerHTML={{__html: this.state.parsed}}>
              </div>
            </div>
          </Row>
        </div><br/>
      </div>
    );
  }
}

export default SimpleEditorComponent;