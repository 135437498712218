// Status403Component.js

import React, { Component } from 'react';

export default class Status403Component extends Component {
    componentDidMount() {
        window.location.href = "https://www.legendsofthejedi.com/"
    }

    render() {
        return (
            <div>
              403
            </div>
        )
    }
}