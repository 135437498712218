import React, { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ColorPicker from 'react-best-gradient-color-picker';
import 'react-quill/dist/quill.snow.css';

const xtermColors = require('../common/xtermColors.json');
const xtermById = {};
xtermColors.forEach((val, id) => xtermById[id] = val);
const Parchment = Quill.import('parchment');
const Delta = Quill.import('delta');
const boxAttributor = new Parchment.Attributor.Class('fgcolor', 'fg', {
    scope: Parchment.Scope.INLINE,
    whitelist: ['none', 'green', 'lgreen', 'dblue', 'cyan', 'blue', 'lblue', 'red', 'lred', 'purple', 'pink', 'orange', 'yellow', 'dgray', 'gray', 'white']
});
Quill.register(boxAttributor);

// Utility color functions, to be separated later.
const hexToRgb = hex => ({r: parseInt(hex.slice(1, 3), 16), g: parseInt(hex.slice(3, 5), 16), b: parseInt(hex.slice(5, 7), 16)});
const cssToRgb = css => {
  const matches = css.match(/(\d+)/g);
  return {r: parseInt(matches[0]), g: parseInt(matches[1]), b: parseInt(matches[2])};
};
const rgbToCss = rgb => (`rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`);
const nearestColor = css => {
  const c = cssToRgb(css);
  var leastDistance = Number.MAX_VALUE;
  var nearest = "";
  Object.entries(xtermById).forEach(v => {
    const r = hexToRgb(v[1]);
    const dist = (r.r - c.r) * (r.r - c.r) + (r.g - c.g) * (r.g - c.g) + (r.b - c.b) * (r.b - c.b);
    if (dist < leastDistance) {
      leastDistance = dist;
      nearest = v[0];
    }
  });
  return nearest;
};

class ColorEditorComponent extends Component {
  constructor(props) {
    super(props);
    this.quillRef = null;
    this.reactQuillRef = null;
    this.onChange = props.onChange;
    this.state = {
      color: 'rgba(255, 255, 255, 1)',
      lotjColor: 15,
      bufferColors: [],
    }
  }

  componentDidMount() {
    this.attachQuillRefs();
  }

  componentDidUpdate() {
    this.attachQuillRefs();
  }

  handleChange = (content, delta, source, editor) => {
    this.updateBufferColors();
    if (typeof this.onChange == 'function') {
      const parsed = this.parseDelta(editor.getContents().ops).replace(/&D(.*)&D/s, "$1");
      this.onChange(parsed);
    }
  };

  handlePickerChange = color => {
    const nearest = nearestColor(color);
    this.setState({
      color: color,
      lotjColor: nearest
    });
  };

  handleLotjColorClick = e => this.quillRef.format('color', xtermColors[this.state.lotjColor]);

  handleColorInputEdit = e => {
    const value = Number(e.target.value);
    if (Number.isInteger(value) && value !== '') {
      const lotjColor = Math.max(Math.min(value, 255), 0)
      this.setState({
        color: rgbToCss(hexToRgb(xtermColors[lotjColor])),
        lotjColor: lotjColor
      });
    }
  };

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== 'function' || this.quillRef != null) return;
    this.quillRef = this.reactQuillRef.getEditor();
    this.quillRef.root.addEventListener('copy', e => this.copyParsed(e));
    this.quillRef.root.addEventListener('cut', e => this.copyParsed(e, true));
    this.quillRef.root.addEventListener('paste', e => this.pasteParsed.bind(this));
    document.querySelectorAll(".ql-picker-item").forEach(val => {
      const rgb = hexToRgb(val.dataset.value);
      val.addEventListener("click", () => this.handlePickerChange(`rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`));
    });

    if (this.props.bufferWidth > 0) {
      const qlEditor = document.querySelector('.ql-editor');
      qlEditor.style.width = `calc(${this.props.bufferWidth + 1}ch + 30px)`;
    }
  };

  updateBufferColors = () => {
    if (this.quillRef === null) return;
    const delta = this.quillRef.getContents();
    var colors = {};
    delta.ops.forEach(op => {
      if (op.attributes) {
        if (op.attributes.color) {
          colors[op.attributes.color] = true;
        } else {
          op.attributes.forEach(attr => {
            if (attr.color) colors[attr.color] = true;
          });
        }
      }
    });

    this.setState({bufferColors: Object.keys(colors).map(c => rgbToCss(hexToRgb(c)))});
  }

  copyParsed(e, cut = false) {
    e.preventDefault();
    var range = this.quillRef.selection.getRange()[0];
    if (range.length === 0) return;

    var rDelta = this.quillRef.getContents(range);
    var parsed = this.parseDelta(rDelta.ops);
    e.clipboardData.setData('text/plain', parsed);
    if (cut) {
      this.quillRef.deleteText(range, Quill.sources.USER);
    }
  }

  colorToDelta(text) {
    var delta = new Delta();
    // Too lazy to fix regex for catching the last match.
    text = text.replace('&D', '') + '&w';
    var parts = text.match(/(&[xgbczGBCrOpwRYPWUI])?.+?(?=&|$)|\n/gm);
    for (var part in parts) {
      var op = this.getOp(parts[part]);
      delta.insert(op.raw, op.attr);
    }
    return delta;
  }

  pasteParsed(e) {
    e.preventDefault();
    var range = this.quillRef.getSelection(true);
    if (range == null) return;
    var pasteDelta = new Delta();
    var cb = e.clipboardData.getData('text/plain');
    // Too lazy to fix regex for catching the last match.
    cb = cb.replace('&D', '') + '&w';
    var parts = cb.match(/(&[xgbczGBCrOpwRYPWUI])?.+?(?=&|$)|\n/gm);
    for (var part in parts) {
      var op = this.getOp(parts[part]);
      pasteDelta.insert(op.raw, op.attr);
    }
    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .concat(pasteDelta);
    this.quillRef.updateContents(delta, Quill.sources.USER);
    this.quillRef.setSelection(delta.length() - range.length,
      Quill.sources.SILENT);
    this.quillRef.scrollIntoView();
  }

  getOp(raw) {
    var tags = raw.match(/&[xgbczGBCrOpwRYPWUID]|&\d{3}/gm);
    var attr = {};
    for (var tid in tags) {
      var tag = tags[tid];
      if (tag.length === 4) {
        attr.color = xtermColors[parseInt(tag.replace('&', ''))]
      }
      if (tag === '&U') {
        attr.underline = true;
      }
      if (tag === '&I') {
        attr.italic = true;
      }
      else {
        switch (tag) {
          case '&x':
            attr.color = xtermColors[0];
            break;
          case '&g':
            attr.color = xtermColors[2];
            break;
          case '&G':
            attr.color = xtermColors[10];
            break;
          case '&b':
            attr.color = xtermColors[4];
            break;
          case '&c':
            attr.color = xtermColors[6];
            break;
          case '&B':
            attr.color = xtermColors[12];
            break;
          case '&C':
            attr.color = xtermColors[14];
            break;
          case '&r':
            attr.color = xtermColors[1];
            break;
          case '&R':
            attr.color = xtermColors[9];
            break;
          case '&p':
            attr.color = xtermColors[5];
            break;
          case '&P':
            attr.color = xtermColors[13];
            break;
          case '&O':
            attr.color = xtermColors[3];
            break;
          case '&Y':
            attr.color = xtermColors[11];
            break;
          case '&z':
            attr.color = xtermColors[8];
            break;
          case '&w':
            attr.color = xtermColors[7];
            break;
          case '&W':
            attr.color = xtermColors[15];
            break;

          default:
            break;
        }
      }
      raw = raw.replace(tag, '');
    }
    return { raw, attr };
  }

  parseDelta(delta) {
    var parsed = '';
    for (var op in delta) {
      parsed += this.wrapOp(delta[op]);
    }
    return parsed;
  }

  wrapOp(op) {
    var result = op.insert
    if (op.attributes == null) {
        return '&D' + result
    }

    // Avoid duplicate clears.
    var needClear = false;

    if (op.attributes.color) {
        var cid = xtermColors.indexOf(op.attributes.color).toString();
        while (cid.length < 3) cid = "0" + cid;
        result = '&' + cid + result;
    }


    if (op.attributes.italic) {
        result = '&I' + result;
        needClear = true;
    }

    if (op.attributes.underline) {
        result = '&U' + result;
        needClear = true;
    }

    if (needClear) result += '&D';

    return result;
  }

  modules = {
    toolbar: [
      ['italic', 'underline', 'strike'],
      [{ 'color': xtermColors }]
    ]
  }

  render() {
    
    return (
      <Row id="editor">
        <Col id="colorTextEditorContainer">
          <ReactQuill
            ref={(el) => { this.reactQuillRef = el; }}
            theme="snow"
            modules={ this.modules }
            value={ this.colorToDelta(this.props.value) }
            onChange={ this.handleChange } />
        </Col>
        <Col id="colorPickerContainer">
          <ColorPicker value={ this.state.color } onChange={ this.handlePickerChange } hideControls={true} hidePresets={true} hideAdvancedSliders={true} hideColorGuide={true} />
          <div id="colorPreview">
            <div id="colorPreviewReal" style={{ backgroundColor: this.state.color }}></div>
            <div id="colorPreviewLotj" onClick={this.handleLotjColorClick} style={{ backgroundColor: rgbToCss(hexToRgb(xtermById[this.state.lotjColor])) }}>
              <input type={"number"} id="pickerLotjNumberInput" value={this.state.lotjColor} onChange={ this.handleColorInputEdit } onClick={ e => e.stopPropagation() } />
            </div>
          </div>
          <div id="currentColors">
            <p id="currentColorsLabel">Buffer colors</p>
            <div id="currentColorsContainer">
              {this.state.bufferColors.map(bColor => {
                return <div className="currentColorSquare" style={{backgroundColor: bColor}} onClick={e => this.handlePickerChange(bColor)}></div>;
              })}
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default ColorEditorComponent;