import http from '../http-common'

class BufferDataService {
  get(game, id) {
    return http.get(`/${game}/api/buffer/${id}`)
  }

  update(game, id, data) {
    return http.put(`/${game}/api/buffer/${id}`, data)
  }
}

export default new BufferDataService();