// EditBufferComponent.js

import React, { Component } from 'react';
import BufferDataService from '../services/buffer.service';
import Button from 'react-bootstrap/Button';
import ColorEditorComponent from './ColorEditorComponent';
import SimpleEditorComponent from './SimpleEditorComponent';
import MonacoEditor from '@monaco-editor/react';
import { withRouter } from '../common/with-router';

class EditBufferComponent extends Component {
  constructor(props) {
    super(props)
    this.onChangeText = this.onChangeText.bind(this);
    this.submitBuffer = this.submitBuffer.bind(this);

    this.state = {
      game: "",
      buffer: {
        id: null,
        text: "",
      },
      message: "",
      status: "editing"
    };
  }

  updateSwitcherMenu(active) {
    if (typeof this.props.onLoaded == 'function') {
      if (!active) {
        this.props.onLoaded([], null);
        return;
      }

      const { buffer } = this.state;
      var currentEditor = "wysiwyg";
      var editors = [
        {name: 'WYSIWYG', value: 'wysiwyg'},
        {name: 'Simple', value: 'simple'},
      ];

      if (buffer.flags.match("lua")) {
        editors = [
          ...editors,
          {name: 'Lua', value: 'lua'}
        ];
        currentEditor = "lua";
      }

      this.props.onLoaded(editors, currentEditor);
    }
  }

  onChangeText(text, e) {
    this.setState(function(prevState) {
      return {
        buffer: {
          ...prevState.buffer,
          text: text
        }
      }
    })
  }

  submitBuffer(e) {
    var data = {
      text: this.state.buffer.text,
    };
    BufferDataService.update(this.state.game, this.state.buffer.id, data)
      .then(res => {
        this.setState({
          message: "Updated successfully",
          status: "saved"
        });
        this.updateSwitcherMenu(false);
      })
      .catch(err => {
        window.alert(err.response.data.message);
      });
  }

  componentDidMount() {
    this.getBuffer(this.props.router.params.game, this.props.router.params.id);
  }

  getBuffer(game, id) {
    BufferDataService.get(game, id)
      .then(res => {
        this.setState({
          game: game,
          buffer: res.data
        }, () => {
          this.updateSwitcherMenu(true);
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { buffer } = this.state;
    var editor = <div>Loading...</div>;
    if (typeof buffer.flags != "undefined" ) {
      // if (buffer.flags.match("lua")) {
      if (this.props.editor === "lua") {
        editor = <MonacoEditor language="lua" value={ buffer.text } theme="vs-dark" onChange={ this.onChangeText } />;
      } else if (this.props.editor === "wysiwyg") {
        editor = <ColorEditorComponent bufferWidth={ buffer.maxlinelength } value={ buffer.text } onChange={ this.onChangeText } />;
      } else {
        editor = <SimpleEditorComponent value={ buffer.text } onChange={ this.onChangeText } />;
      }
    }
    if (this.state.status === "saved") {
      return (
        <div>
          <div>Buffer updated.</div>
          <div style={{fontSize: 10, textAlign:'center'}}>(You can close this window)</div>
        </div>
      )
    }
      return (
          <div style={{width: "100vw", height: "80vh"}}>
            {editor}
            <Button id="buffer-submit" type="submit" onClick={this.submitBuffer}>Submit</Button>
          </div>
      );
  }
}

export default withRouter(EditBufferComponent)